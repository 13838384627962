<div id="activitydiv" class="activity-container" style="position: relative;" (scroll)="onScroll($event)">
  <div class="custom-mat-spinner" *ngIf="isSpinnerShown">
    <mat-spinner></mat-spinner>
    <div class="text">Data loading...</div>
  </div>

  <section class="accordianpanel" [class]="isBankTransMode?'banktransPanel':''"
    [ngStyle]="{'border-top': isBankTransMode? 'none' : ''}" >
    <div class="block-title" style="margin-bottom: 15px" *ngIf="!isBankTransMode">
      <span (click)="isActivityExpanded = !isActivityExpanded">Activity</span>
      <span class="shevron" (click)="isActivityExpanded = !isActivityExpanded">
        <img [ngClass]="isActivityExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
      </span>
    </div>
    <div class="block-content" *ngIf="isActivityExpanded" [ngClass]="isActivityExpanded ? 'expaned' : ''">
      <div class="content" *ngIf="!isSpinnerShown">
        <div class="history" *ngIf="isInvoiceMode && activities?.length > 0">
          <div class="activity-card" *ngFor="let activity of activities">
            <div class="label"
              [ngClass]="{'sup_img_box_Upload': (activity.stage == 'Submitted' || activity.stage == 'Document Submitted' || activity.stage == 'Sent for Approval'),'sup_img_box_Approved': activity.stage == 'Approved','sup_img_box_Published': activity.stage == 'Published','sup_img_box_Paid': activity.stage == 'Paid'       }">
              <!-- <span>
                {{activity.actor ? activity.actor[0].toUpperCase() : 'U'}}
              </span> -->
            </div>
            <div class="desc">
              <div class="stage" [ngClass]="!activity.actor && !activity.date ? 'no-margin-bottom' : ''">
                {{activity.stage}}<span *ngIf="activity.stage.toLowerCase() == 'submitted'">{{activity.model}}</span></div>
              <div class="actor-date" *ngIf="activity.date">
                <span class="date-span" *ngIf="activity.date">{{getFormattedDate(activity.date)}}</span>
              </div>
              <div class="detail bg-gray"
                *ngIf="(activity.submissionMethod !== 'By Email' && activity.submissionMethod !== 'By Gmail Extension' && activity.submissionMethod !== 'By Inbox Assistant' && activity.submissionMethod !== 'By Outlook Addin')">
                <div class="" *ngIf="activity.submissionMethod && activity.stage != 'Published'">
                  {{activity.submissionMethod}}</div>
                <div class="" *ngIf="activity.actor">
                  <span *ngIf="activity.actor">By {{activity.actor}}&nbsp;</span>
                  <span *ngIf="activity.isAutoPublished">
                    <img class="lightning" src="../../../../../assets/img/invoiceForm/lightning.svg" alt="">
                  </span>
                </div>
                <div class="note">{{activity.note}}</div>
              </div>
              <div class="detail bg-gray"
                *ngIf="(activity.submissionMethod === 'By Email' || activity.submissionMethod === 'By Gmail Extension' || activity.submissionMethod === 'By Inbox Assistant' || activity.submissionMethod === 'By Outlook Addin')">
                <div class="" *ngIf="activity.submissionMethod && activity.stage != 'Published'">
                  {{activity.submissionMethod}}
                  <ng-container *ngIf="activity.submissionMethod === 'By Inbox Assistant' && activity.details != null">
                    <i class="fa fa-info-circle tooltip-title" aria-hidden="true"> <span
                        class="tooltiptext">{{activity.details}}</span></i></ng-container>
                </div>

                <div class="" *ngIf="activity.actor">
                  <span *ngIf="activity.actor">{{activity.actor}}&nbsp;</span>
                  <span *ngIf="activity.isAutoPublished">
                    <img class="lightning" src="../../../../../assets/img/invoiceForm/lightning.svg" alt="">
                  </span>
                </div>
                <div class="" *ngIf="activity.from">
                  <br>
                  <div *ngIf="activity.from">From: {{activity.from}}&nbsp;</div>
                  <div *ngIf="activity.subject">Subject: {{activity.subject}}&nbsp;</div>

                  <div class="container body-container" [class.show]="activity.visible" *ngIf="activity.body"
                    [innerHtml]="activity.body">
                    &nbsp;
                    <br />
                    <br />
                  </div>
                  <a *ngIf="activity.body" class="showmore" (click)="activity.visible = !activity.visible">{{
                    activity.visible ? 'Show less': 'Show More' }} </a>
                </div>
                <div class="" *ngIf="!activity.from && activity.stage != 'Published'">
                  <div class="history no-activity-detail">
                    No details to display
                  </div>
                </div>
              </div>
            </div>
            <div class="line" *ngIf="activities.length > 1"></div>
          </div>
        </div>
        <div class="history" *ngIf="isBankTransMode && activities?.length > 0">

          <div class="activity-card"
            style="grid-template-columns: 1fr;border-bottom:1px solid #EBEBEB;width: 100%;text-align: center; padding-bottom: 14px;">
            <div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                class=" activityArrow" (click)="changeTransaction('prev')">
                <g clip-path="url(#clip0_5964_2314)">
                  <path d="M7.99 13L20 13V11L7.99 11L7.99 8L4 12L7.99 16V13Z" fill="#1C1C1C" />
                </g>
                <defs>
                  <clipPath id="clip0_5964_2314">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)" />
                  </clipPath>
                </defs>
              </svg>

              <span style="padding:5px 10px;">
                {{curIdx+1}} of {{activities.length}}
              </span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                class=" activityArrow" (click)="changeTransaction('next')">
                <g clip-path="url(#clip0_5964_2311)">
                  <path d="M16.01 11H4V13H16.01V16L20 12L16.01 8V11Z" fill="#1C1C1C" />
                </g>
                <defs>
                  <clipPath id="clip0_5964_2311">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </div>
          </div>
          <div>
            <app-bank-transaction-detail [activities]="activities" [curIdx]="curIdx"
              (assingTrans)="assignTrans($event)"></app-bank-transaction-detail>
          </div>
        </div>
        <div class="history" *ngIf="!isInvoiceMode && !isBankTransMode && activities?.length > 0">
          <div class="activity-card" *ngFor="let activity of activities">
            <div class="supplier-label">
              <span></span>

            </div>
            <div class="desc">
              <div class="stage" [ngClass]="!activity.actor && !activity.date ? 'no-margin-bottom' : ''">
                {{activity.stage}}</div>
              <div class="actor-date" *ngIf="activity.date">
                <span class="date-span" *ngIf="activity.date">{{activity.date | formatedDate}} at {{activity.date |
                  date:'h:mm a'}}</span>
              </div>
              <div class="detail bg-gray" *ngIf="activity.actor">
                <div class="" *ngIf="activity.submissionMethod">{{activity.submissionMethod}}</div>
                <div class="" *ngIf="activity.actor">
                  <span *ngIf="activity.actor">By {{activity.actor}}&nbsp;</span>
                  <span *ngIf="activity.isAutoPublished">
                    <img class="lightning" src="../../../../../assets/img/invoiceForm/lightning.svg" alt="">
                  </span>
                </div>
              </div>
            </div>
            <div class="line supplier-line" *ngIf="activities.length > 1"></div>
          </div>
        </div>
        <div class="history no-activity" *ngIf="!activities || activities?.length == 0">
          No activity data
        </div>
      </div>
    </div>
  </section>

  <section class="accordianpanel accordianRelated" *ngIf="isBankTransMode">
    <div class="block-title" style="margin-bottom: 5px">
      <span>Related</span>
    </div>
    <div class="block-content">
      <ngx-file-drop (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <input hidden #filesrc type="file" accept=".pdf, .png, .jpg, .jpeg" (change)="added($event)">
          <div class="note-content payment-note">

            <ng-container [width]="200" *ngFor="let row of invoiceLines">
              <a href="/inbox/editInvoice/{{this.activities[this.curIdx]['relatedID']}}" target="_blank" class="relatedFiles">
                <div class="related-detail-wrap">
                  <div class="supplier">
                    <div class="sup_img_box {{row.hexColorClass}}"
                      *ngIf="row.rowCount != 0 && row.supplierName != null && row.hexColorClass != null">
                      <span>{{row.shortCode}}</span>
                    </div>
                    <div class="sup_img_box"
                      *ngIf="row.rowCount != 0 && row?.supplierName != null && row.hexColorClass == null" [ngClass]="{'sup_img_box_1': row.rowCount == 1 && row.hexColorClass == null,
                              'sup_img_box_2': row.rowCount == 2 && row.hexColorClass == null,
                              'sup_img_box_3': row.rowCount == 3 && row.hexColorClass == null,
                              'sup_img_box_4': row.rowCount == 4 && row.hexColorClass == null,
                              'sup_img_box_5': row.rowCount == 5 && row.hexColorClass == null,
                              'sup_img_box_6': row.rowCount == 6 && row.hexColorClass == null}">
                      <span>{{row?.shortCode}}</span>
                    </div>
                    <div class="sup_img_box"
                      *ngIf="row.rowCount != 0 && (row?.supplierName == null || row?.supplierName == '')"
                      [ngClass]="{'sup_img_box_1': row.rowCount == 1,'sup_img_box_2': row.rowCount == 2,'sup_img_box_3': row.rowCount ==3,'sup_img_box_4': row.rowCount == 4,'sup_img_box_5': row.rowCount == 5,'sup_img_box_6': row.rowCount == 6}">
                      <span>{{row?.shortCode}}</span>
                    </div>
                    <div class="sup_det">
                      <div class="sd_name ">
                        <span class="name" *ngIf="row.supplierName">{{row.supplierName}} </span>
                        <span class="name field-placeholder" *ngIf="!row.supplierName">Add Supplier name</span>
                        <!-- <div  class="clsContStatus">Demo</div> -->
                        <div *ngIf="!row.isDemo && row.contactStatus !=''" class="clsContStatus">{{row.contactStatus}}
                        </div>
                        <div *ngIf="row.isDemo" class="clsDemo">Demo</div>
                        <!-- <img *ngIf="row?.submissionMethod == 3" src="../../../../../assets/img/inboxPage/addin.png" alt="Submitted via Inbox Assistant" class="addin-logo" title="Submitted via Inbox Assistant" /> -->

                      </div>

                      <div class="sd-mobile">
                        <span class="sd_invoice invoice-number">{{row.date | formatedDate}}</span>
                        <span class="sd_invoice invoice-number"
                          *ngIf="row.accountName">&nbsp;|&nbsp;{{row.accountName}}</span>
                      </div>
                    </div>

                  </div>
                  <div class="amountcell mobile">
                    <span *ngIf="row.amountDue === 'Infinity'"> {{ row.total | number : '1.2-2'}}</span>
                    <span *ngIf="row.amountDue > 0"> {{ row.amountDue | number : '1.2-2'}}</span>

                  </div>
                </div>
              </a>
            </ng-container>
            <ng-container>
              <div *ngIf="relatedName">
                <!-- <ng-container *ngIf="relatedID">
                <a href="/inbox/editInvoice/{{this.relatedID}}" target="_blank">
                  <span class="clsAttachCard">
                    <img src="../../../../../assets/img/extention/{{this.relatedName.split('.')[1].toLowerCase()}}.svg"
                        style="width: 30px;">&nbsp;{{this.relatedName}}
                  </span>
                </a>
              </ng-container> -->
                <ng-container *ngIf="this.activities[this.curIdx]['relatedID']==null">
                  <span class="clsAttachCard" (click)="sendFile()" [ngClass]="isFileProcessing?'load':''">
                    <span class="fileProcess " style="padding-left: 40px;line-height: 36px;"
                      [ngClass]="isFileProcessing?'active load':''">
                      Processing
                    </span>
                    <span class="fileProcess " [ngClass]="!isFileProcessing?'active':''">
                      <img
                        src="../../../../../assets/img/extention/{{this.relatedName.split('.')[1].toLowerCase()}}.svg"
                        style="width: 30px;">&nbsp;{{this.relatedName}}
                    </span>
                  </span>
                  <!-- </a> -->
                </ng-container>
              </div>
            </ng-container>
            <span class="clsAddRelated" *ngIf="invoiceLines.length == 0" (click)="filesrc.click()">
              + Add Related
            </span>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>

  </section>

  <section class="accordianpanel" style="width: 453px;height: 350px;background: #FFF;">
    <div class="block-title" style="margin-bottom: 15px" *ngIf="!isBankTransMode">
      <span (click)="isNoteExpanded = !isNoteExpanded">Notes</span>
      <span class="shevron" (click)="isNoteExpanded = !isNoteExpanded">
        <img [ngClass]="isNoteExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
      </span>
    </div>
    <div class="block-title" style="margin-bottom: 15px" *ngIf="isBankTransMode">
      <span (click)="isNoteExpanded = !isNoteExpanded">Comments</span>
    </div>
    <div class="block-content" *ngIf="isNoteExpanded" [ngClass]="isNoteExpanded ? 'expaned' : ''">
      <div class="content note-content" style="max-height: 300px;overflow-y: auto;overflow-x: hidden;"
        *ngIf="!isSpinnerShown && this.activities.length >0">

        <app-notes [noteType]="noteType" [recordId]="recordId" [relatedID]="this.activities[this.curIdx]['relatedID']"
          [relatedName]="this.activities[this.curIdx]['relatedName']" (transNote)="transNote($event)"
          [isBankTransMode]="isBankTransMode" (uploadedFile)="uploadFile($event)"></app-notes>
      </div>
    </div>
  </section>
</div>
