// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiURL: 'http://localhost:51015/api/',
  // frontURL: 'http://localhost:4200/',

  // Staging

  // apiURL: 'https://syncedtestingapi.azurewebsites.net/api/',
  // frontURL: 'https://syncedtesting.azurewebsites.net/',
  // Intercom_app_id:'hvba3rbc',

  //Production
  apiURL: 'https://syncedapi.azurewebsites.net/api/',
  frontURL: 'https://app.syncedhq.com/',
  Intercom_app_id: 'hbch4hic',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
const xeroClientId = 'B1FBCF253E474AE29998C451E960B0CE';
const xeroCallbackUrl = 'https://syncedapi.azurewebsites.net/api/Organisation/Xerocallback';
// const xeroCallbackUrl ='http://localhost:51016/api/Organisation/Xerocallback';
export const xeroParams = {
  xeroOauth2Link: 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=' +
    xeroClientId + '&redirect_uri=' + xeroCallbackUrl + '&scope=openid profile offline_access accounting.transactions accounting.settings accounting.contacts accounting.attachments accounting.transactions accounting.reports.read accounting.reports.bankstatement.read email&state=123',
  xeroClientId: xeroClientId
};

const xeroCallbackUrlForConnection = 'https://syncedapi.azurewebsites.net/api/Organisation/XerocallbackForRefresh';
// const xeroCallbackUrlForConnection ='http://localhost:51016/api/Organisation/XerocallbackForRefresh';
export const xeroParamsForConnection = {
  xeroOauth2Link: 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=' +
    xeroClientId + '&redirect_uri=' + xeroCallbackUrlForConnection + '&scope=openid profile offline_access accounting.transactions accounting.settings accounting.contacts accounting.attachments accounting.transactions accounting.reports.read accounting.reports.bankstatement.read email&state=123',
  xeroClientId: xeroClientId
};

const xeroSignUpCallbackUrl = 'https://syncedapi.azurewebsites.net/api/Account/XeroSignUp';
// const xeroSignUpCallbackUrl ='http://localhost:51016/api/Account/XeroSignUp';
export const xeroSignUpConnection = {
  xeroOAuth2SignUpLink: 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=' +
    xeroClientId + '&redirect_uri=' + xeroSignUpCallbackUrl + '&scope=openid profile offline_access accounting.transactions accounting.settings accounting.contacts accounting.attachments accounting.transactions accounting.reports.read accounting.reports.bankstatement.read email&state=afiGl5r9s2w'
};

const xeroSignInCallbackUrl = 'https://syncedapi.azurewebsites.net/api/Account/XeroSignIn';
// const xeroSignInCallbackUrl ='http://localhost:51016/api/Account/XeroSignIn';
export const xeroSignInConnection = {
  xeroOAuth2SignInLink: 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=' +
    xeroClientId + '&redirect_uri=' + xeroSignInCallbackUrl + '&scope=openid profile email&state=afiGl5r9s2w'
};





//QuickBook
const QuickBookClientId = 'AB2EKHM3FnbYBJ5MJB2Ya052JQYMvHlM74CAvtuz0lOapiPdSS';
const QuickBookClientSecret = '1MebMZTPO8xCrAPD5UtliTCYSNqSdiYsEtEFklOe';
const QuickBooksCallback = 'https://syncedapi.azurewebsites.net/api/QuickBooks/QuickBooksCallback';

export const QuickBookParam = {
  QuickBookOauth2Link: 'https://appcenter.intuit.com/connect/oauth2?client_id=' + QuickBookClientId + '&scope=com.intuit.quickbooks.accounting&redirect_uri=' + QuickBooksCallback + '&response_type=code&state=com.intuit.quickbooks.accounting',
  QuickBookClientId: QuickBookClientId
};

//QuickBook Connection refresh
const QuikBookCallbackUrlForConnection = 'https://syncedapi.azurewebsites.net/api/QuickBooks/QuickBookCallbackForRefresh';
export const QuickBookConnection = {
  QuickBookOauth2Link: 'https://appcenter.intuit.com/connect/oauth2?client_id=' + QuickBookClientId + '&scope=com.intuit.quickbooks.accounting&redirect_uri=' + QuikBookCallbackUrlForConnection + '&response_type=code&state=com.intuit.quickbooks.accounting',
  QuickBookClientId: QuickBookClientId
}

export const QuickBookLink = 'https://app.sandbox.qbo.intuit.com/app';

export const EmailDownloadLink = 'https://emailsyncing.azurewebsites.net/download/'; 
