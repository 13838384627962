import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { InvoiceActivity } from '../../models/activity/activity';
import { UserService } from 'src/app/data/service/user.service';
import { NotesComponent } from '../notes/notes.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { take } from 'rxjs/operators';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent extends BaseComponent implements OnInit {
  @Output() moveBack = new EventEmitter<void>();
  @Output() deleteTrans = new EventEmitter<string>();
  @Output() assignTransa = new EventEmitter<object>();
  @Output() transNotes = new EventEmitter<object>();
  @Input() supplierName: string = '';
  @Input() invoiceId: string = null;
  @Input() supplierId: string = null;
  @Input() isInvoiceMode: boolean = true;
  @Input() invoiceColorNumber: number = 1;
  @Input() isBankTransMode: boolean = false;
  @Input() bankTransArr:any=[];
  noteType: string = '';
  recordId: string = '';
  showToolTipDelay: number = 400;
  activities = [] as InvoiceActivity[];
  isSpinnerShown: boolean = false;
  isActivityExpanded: boolean = true;
  isNoteExpanded: boolean = true;
  organizationId: string = null;
  usersList: any[] = [];

  curIdx:number =0;
  shownCategoriesInvoiceId:number=1;
  // row ={
  //   rowCount:1,supplierName:'Demo Company (AU)',hexColorClass:"sup_img_box_1",shortCode:"JD",isDemo:false,contactStatus:true,invoiceNumber:'123456',accountName:"Donations",total:123,amountDue:234,date:new Date('2024-03-12'),submissionMethod:3
  // }
  invoiceLines:any=[];

  isStatusChg:boolean=false;
  noteMentions: any[] = [];
  @ViewChild('noteinput') noteinput: ElementRef;
  @ViewChild('commentModel') commentModel: ModalDirective;
  constructor(
    private readonly _invoiceService: InvoiceService,
    private readonly _toasterMessageService: ToasterMessageService,
    private readonly _organizationService: OrganizationService,
    private readonly _supplierService: SuppliersService,
    private _userService: UserService,
    private confirmationDialogService: ConfirmationDialogService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private router: Router
  ) {
    super();
  }
  @ViewChild(NotesComponent) notesComponent: NotesComponent;
  @HostListener('click', ['$event'])
  onClick(event): void {
    this.notesComponent?.hideEmoji(event);
  }
  ngOnInit(): void {
    if (this.isInvoiceMode) {
      this.isSpinnerShown = true;

      this._invoiceService.getInvoiceActivityById(this.invoiceId, this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (data: InvoiceActivity[]) => {
          this.activities = this.activities.concat(data);
          this.isSpinnerShown = false;

          let actors = [] as string[];
          this.activities?.forEach(element => {
            if (actors?.length == 0) {
              actors.push(element.actor);
            } else if(!actors.includes(element.actor)) {
              actors.push(element.actor);
            }
            element.visible = false;
          });

          if (actors && actors.length > 0) {
            this.activities.forEach(element => {
              element.colorNumber = actors.indexOf(element.actor) + 1;
            });
          }
        },
        (e: Error) => {
          this.isSpinnerShown = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        });
    }
    if (this.isBankTransMode) {
     this.organizationId = this._organizationService.selectedOrganization;
     this.isSpinnerShown = true;

     this.activities = this.bankTransArr;

     this.invoiceId=this.activities[this.curIdx]['unreconciledReportId'];

     this.getRelatedData();



     // .pipe(this.untilThis)
     // .subscribe(
     //   (data: InvoiceActivity[]) => {

         // let actors = [] as string[];
         // this.activities?.forEach(element => {
         //   if (actors?.length == 0) {
         //     actors.push(element.actor);
         //   } else if(!actors.includes(element.actor)) {
         //     actors.push(element.actor);
         //   }
         //   element.visible = false;
         // });

         // if (actors && actors.length > 0) {
         //   this.activities.forEach(element => {
         //     element.colorNumber = actors.indexOf(element.actor) + 1;
         //   });
         // }
       // },
       // (e: Error) => {
       //   this.isSpinnerShown = false;
       //   this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
       // });
    }

    else {
        this.isSpinnerShown = true;
        this._supplierService.getSupplierActivityById(this._organizationService.selectedOrganization, this.supplierId)
        .pipe(this.untilThis)
        .subscribe(
          (data) => {
            this.isSpinnerShown = false;
            this.activities = this.activities.concat(data.filter(a => a.date != null));
          },
          (e: Error) => {
            this.isSpinnerShown = false;
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          });
     }
    this.setNoteType();
  }
  onScroll(event: any) {
    if(document.querySelector("#activitydiv").scrollTop < 70){
      // document.getElementsByClassName("transIcon")[0].style.top=20;
      const highlights = document.getElementsByClassName('transIcon');
      for (let i = 0; i < highlights.length; i++) {
        const element = highlights[i] as HTMLElement;
        element.style.top = (16-document.querySelector("#activitydiv").scrollTop)+'px';
      }
    };
  }

  getRelatedData(){
    if(this.activities[this.curIdx]['relatedID'] != "")
      {
        // this.relatedName=fileObj.file.name;
        this.relatedID =this.activities[this.curIdx]['relatedID'];
        this.relatedName= this.activities[this.curIdx]['relatedName'];

          this._invoiceService.getRelatedData( this._organizationService.selectedOrganization,this.activities[this.curIdx]['relatedID'],).subscribe(
         (data: any) => {
           this.isSpinnerShown = false;
           this.invoiceLines=data;
            this.prepareProcessedInvoices(this.invoiceLines);
            if(data.length > 0){
              this.activities[this.curIdx]['status']= this.invoiceLines[0].amountDue+ this.activities[this.curIdx]['amount'] == 0?'Actioned':this.activities[this.curIdx]['status'];
              this.activities[this.curIdx]['ischeck']= this.invoiceLines[0].amountDue+ this.activities[this.curIdx]['amount'] == 0?false:true;
            }
         });

       }
       else{
        this.isSpinnerShown = false;
       }
  }

  private prepareProcessedInvoices(array: any) {
    let count = 1;
    array.forEach(element => {

      let stringToSplit = element.supplierName;

      if (stringToSplit) {
        let x = stringToSplit.split(" ");
        if (x.length == 1) {
          element.shortCode = x[0].charAt(0);
        }
        if (x.length == 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0);
        }
        if (x.length > 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0) + x[2].charAt(0);
        } if (x.length == 0) {
          element.shortCode = "NA";
        }
      }

      element.rowCount = count;
      count = count + 1;
      if (count == 7) {
        count = 1;
      }

    });
  }


  changeTransaction(type:string){
    if(type=='prev' && this.curIdx ==0)
    {
      return;
    }
    else if(type=='next' && this.curIdx  == this.activities.length-1 )
    {
      return;
    }

    this.isNoteExpanded=false;
    this.recordId="";
    this.isSpinnerShown = true;
    setTimeout(()=>{
    if(type=='prev')
    {
      this.curIdx=this.curIdx ==0 ?0:(this.curIdx-1);
      this.recordId=this.activities[this.curIdx]['unreconciledReportId'];
    }
    else if(type=='next' ){
      this.curIdx=this.curIdx ==this.activities.length-1 ?this.curIdx:(this.curIdx+1);
      this.recordId=this.activities[this.curIdx]['unreconciledReportId'];
    }
    this.isNoteExpanded=true;
    this.isSpinnerShown = false;
    this.getRelatedData();
  })
  }


  setNoteType() {
    this.recordId = this.invoiceId;
    this.noteType = 'Invoice';
    if (this.router.url.toLowerCase().indexOf('suppliers') > 0) {
      this.noteType = 'Contact';
      this.recordId = this.supplierId;
    }
    if (this.isBankTransMode) {
      this.noteType='unmatched';
    //this.recordId='15e3f792-64ba-4b10-96a7-288651214740';
    }
  }
  moveBackClick() {
    this.moveBack.emit();
  }

  datePipe: DatePipe = new DatePipe(this.getNavigatorLanguage());
  getFormattedDate(date: Date) {
    var createdDate = this.datePipe.transform(date + 'Z', 'dd/MM/yy', Intl.DateTimeFormat().resolvedOptions().timeZone, this.getNavigatorLanguage());
    var todaydate = new Date();
    var currentDate = this.datePipe.transform(todaydate, 'dd/MM/yy');
    if (createdDate == currentDate) {
      return "Today at " + this.datePipe.transform(date + 'Z', 'h:mm a', Intl.DateTimeFormat().resolvedOptions().timeZone, this.getNavigatorLanguage());
    }
    else {
      return createdDate + " at " + this.datePipe.transform(date + 'Z', 'h:mm a', Intl.DateTimeFormat().resolvedOptions().timeZone, this.getNavigatorLanguage());
    }
  }

  getNavigatorLanguage() {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    }
    else {
      return "en-US";
    }
  }

  removeTrans() {

    this.confirmationDialogService.confirm('Confirm', 'Do you want to delete this transaction?', 'Yes', 'No', '')
      .then((confirm) => {
        if (confirm) {
          let data ={Action:'removetrans'}
          this.saveTransactionStatus(data);
        }
        else {
          // this.DeleteSavedReport();
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));


    // if(confirm("Are you sure to delete tranaction?")){
    //   let data ={Action:'removetrans'}

    //   this.saveTransactionStatus(data);

    // }
  }
  saveTransactionStatus(data) {
    data.AssignedBy= sessionStorage.Id,
    data.UnreconciledReportId = this.activities[this.curIdx]['unreconciledReportId'],

    this._organizationService.saveTransactionStatus(data)
      .subscribe(
         (result: any) => {
          if(result.status==1){
            if(data.Action=='removetrans')
            {
              this.deleteTrans.emit(this.activities[this.curIdx]['unreconciledReportId']);
            }
            this.activities.splice(this.curIdx);
            this.curIdx =(this.activities.length > this.curIdx || this.curIdx==0) ?this.curIdx:this.curIdx-1;
            this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', result.Message);

          }
          else{
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', result.Message);
          }
         }
       );
  }
  assignTrans(obj:any)
  {
    this.assignTransa.emit(obj);
  }
  transNote(obj:any)
  {
    this.transNotes.emit(obj);
  }

  fileObj:any;
  uploadFile(file:any)
  {
    this.fileObj=file;
    this.activities[this.curIdx]['relatedID']=file.id;
    this.activities[this.curIdx]['relatedName']=file.name;
    this.getRelatedData();
  }

  added(event: Event) {
    // this.loading = true;
     const [uploadedFile] = Array.from((event.target as HTMLInputElement).files as FileList);

     let  fileObj={name:uploadedFile.name,file:uploadedFile};
     this.relatedName=fileObj.file.name;
     this.fileObj=fileObj;
     this.relatedID =null;
     this.sendFile();

   }
  relatedID: string = null;
  relatedName: string = '';
  uploadedFile:any;
  isFileProcessing:boolean=false;
  dropped(uploadedFiles: NgxFileDropEntry[]) {
    const fileEntry = uploadedFiles[0].fileEntry.isFile ? uploadedFiles[0].fileEntry as FileSystemFileEntry : null;
    let  fileObj:any;
    fileEntry?.file((files: File) => {
      fileObj={name:files.name,file:files};
      this.fileObj=fileObj;
      this.relatedName=fileObj.file.name;
      this.relatedID =null;
      this.sendFile();
    });
  }


  async sendFile(){
     this.isFileProcessing=true;
    // setTimeout(() => {
    //   this.isFileProcessing=false;
    // }, 5000);
   await this._invoiceService.uploadInvoice({ organisationId: this._organizationService.selectedOrganization, invoice: this.fileObj.file, recordId: this.recordId, isBankTransMode: this.isBankTransMode });

    this._recognizedInvoiceService.invoiceState$.pipe(take(1))
        .subscribe(
          (invoice: any) => {
            this.relatedID =invoice.id;
            this.relatedName=this.fileObj.file.name;
            this.uploadFile({name:this.fileObj.file.name,id:invoice.id});
            // this.isFileProcessing=false;
          },
          (e: Error) => {

            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
            // this.isFileProcessing=false;
          }
        );
  }

}
