<div class="sidebar" [ngClass]="sharedService?.isShowSideNav ? 'open' : ''">
    <div class="sidebar_in">
        <div class="stopbar">
            <div class="logo" [ngClass]="{ 'open': sharedService?.isShowSideNav }"
                (click)="sharedService.isShowSideNav=!sharedService.isShowSideNav">
                <!-- <a href="#" [routerLink]="['/dashboard']"> -->
                <img id="toggle_sidebar" src="../../../assets/img/sidebar/synced-black-logo.png" alt="">
                <!-- </a> -->
            </div>
        </div>
        <!-- <div class="nav_button">
            <div id="nav-icon" [ngClass]="{ 'open': sharedService?.isShowSideNav }" (click)="sharedService.isShowSideNav=!sharedService.isShowSideNav">
                <span class="round_toggle"></span>
            </div>
        </div> -->

        <div class="sidebar_menu">
            <div class="top_menu">
              <ul>
                <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': home.isActive && !sharedService?.isShowSideNav }" [routerLinkActiveOptions]="{exact:false}" #home="routerLinkActive">
                  <a href="#" [routerLink]="['/dashboard']" (click)="sharedService.isShowSideNav=false;" >
                    <span class="icon">
                      <img *ngIf="home.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/Home.png" alt="Home">
                      <img *ngIf="!home.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/Home.png" alt="Home">
                    </span>
                    <span class="text">Home</span>
                  </a>
                </li>
                <li>
                  <span class="icon">
                    <hr  [ngClass]="{ 'hr-line': !sharedService?.isShowSideNav }"/>
                  </span>
                  <span class="simple-text">Process</span>
                </li>
                <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': inbox.isActive && !sharedService?.isShowSideNav }" [routerLinkActiveOptions]="{exact:false}" #inbox="routerLinkActive">
                  <a [routerLink]="['/inbox']">
                    <span class="icon">
                      <img *ngIf="inbox.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/Inbox.png" alt="Inbox">
                      <img *ngIf="!inbox.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/Inbox.png" alt="Inbox">
                    </span>
                    <span class="text">Inbox</span>
                  </a>
                </li>
                <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': email.isActive && !sharedService?.isShowSideNav }" [routerLinkActiveOptions]="{exact:false}" #email="routerLinkActive">
                  <a [routerLink]="['/emails']">
                    <span class="icon">
                      <img *ngIf="email.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/Email.png" alt="Emails">
                      <img *ngIf="!email.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/Email.png" alt="Emails">
                    </span>
                    <span class="text">Emails</span>
                  </a>
                </li>
                <li>
                  <span class="icon">
                    <hr [ngClass]="{ 'hr-line': !sharedService?.isShowSideNav }" />
                  </span>
                  <span class="simple-text">Pay</span>
                </li>
                <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': billstopay.isActive && !sharedService?.isShowSideNav }" [routerLinkActiveOptions]="{exact:true}" #billstopay="routerLinkActive">
                  <a href="#" [routerLink]="['/payable/outstanding-amounts']"
                     (click)="RedirectToPayable();sharedService.isShowSideNav=false;">
                    <span class="icon">
                      <img *ngIf="billstopay.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/BillsToPay.png" alt="Bills To Pay">
                      <img *ngIf="!billstopay.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/BillsToPay.png" alt="Bills To Pay">
                    </span>
                    <span class="text">Bills To Pay</span>
                  </a>
                </li>
                <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': payments.isActive && !sharedService?.isShowSideNav }" #payments="routerLinkActive">
                  <a href="#" [routerLink]="['/payable/payments']"
                     (click)="RedirectToPayable();sharedService.isShowSideNav=false;">
                    <span class="icon">
                      <img *ngIf="payments.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/Payments.png" alt="Payments">
                      <img *ngIf="!payments.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/Payments.png" alt="Payments">
                    </span>
                    <span class="text">Payments</span>
                  </a>
                </li>
                <li>
                  <span class="icon">
                    <hr [ngClass]="{ 'hr-line': !sharedService?.isShowSideNav }" />
                  </span>
                  <span class="simple-text">Reconcile</span>
                </li>

                <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': paperwork.isActive && !sharedService?.isShowSideNav }" [routerLinkActiveOptions]="{exact:false}" #paperwork="routerLinkActive">
                  <a href="#" [routerLink]="['/paperwork/missing-paperwork']"
                     (click)="RedirectToPaperwork();sharedService.isShowSideNav=false;">
                    <span class="icon">
                      <img *ngIf="paperwork.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/Paperwork.png" alt="Paperwork">
                      <img *ngIf="!paperwork.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/Paperwork.png" alt="Paperwork">
                    </span>
                    <span class="text">Paperwork</span>
                  </a>
                  <a href="#" [routerLink]="['/paperwork/missing-paperwork']" style="display: none;"></a>
                </li>

                <li>
                  <span class="icon">
                    <hr [ngClass]="{ 'hr-line': !sharedService?.isShowSideNav }" />
                  </span>
                  <span class="simple-text">Automate</span>

                </li>

                <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': automate.isActive && !sharedService?.isShowSideNav }" [routerLinkActiveOptions]="{exact:false}" #automate="routerLinkActive">
                  <a href="#" [routerLink]="['/automate/flows']"
                     (click)="sharedService.isShowSideNav=false;">
                    <span class="icon">
                      <img *ngIf="automate.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/Flows.png" alt="Flows">
                      <img *ngIf="!automate.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/Flows.png" alt="Flows">
                    </span>
                    <span class="text">Flows</span>
                  </a>

                </li>
              </ul>
            </div>
            <div class="bottom_menu">
                <ul>
                    <li [routerLinkActive]="['active']" [ngClass]="{ 'small-icon': settings.isActive && !sharedService?.isShowSideNav }" [routerLinkActiveOptions]="{exact:false}" #settings="routerLinkActive">
                        <a href="#" [routerLink]="['/setting']" (click)="sharedService.isShowSideNav=false;">
                          <span class="icon">
                            <img *ngIf="settings.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/blue-logos/settings.png" alt="Settings">
                            <img *ngIf="!settings.isActive" id="toggle_sidebar" src="../../../assets/img/sidebar/grey-logos/settings.png" alt="Settings">
                          </span>
                            <span class="text">Settings</span>
                        </a>
                    </li>
                    <!-- <li>
                        <a (click)="signOut()">
                            <span class="icon" (click)="sharedService.isShowSideNav=false;">
                                <div [inlineSVG]="'../../../assets/img/sidebar-icon-logout.svg'"></div>
                            </span>
                            <span class="text logout_text">Logout</span>
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</div>
