<ngx-datatable 
  #BookkeepingTable 
  [rows]="invoices" 
  [messages]="{emptyMessage: emptyMessage}" 
  class="material expandable custom"
  [columnMode]="columnMode.force" 
  [scrollbarV]="false" 
  [scrollbarH]="false" 
  [headerHeight]="50" 
  [footerHeight]="50"
  rowHeight="auto" 
  [limit]="(status == 'Processed' ? 25 :  invoices.length)" 
  (activate)="onActivate($event)"
  (sort)="onSort($event)">



  <!-- Checkbox -->
  <ngx-datatable-column name="Checker" [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
    [resizeable]="false">
    <ng-template [width]="30" let-row="row" ngx-datatable-header-template>
      <input type="checkbox" class="header-checkbox" name="chkHeader" [(ngModel)]="isHeaderCheckboxSelected" (change)="onHeaderCheckboxChange()">
    </ng-template>
    <ng-template [width]="30" let-row="row" ngx-datatable-cell-template>
      <input type="checkbox" [(ngModel)]="row.checked" (change)="onCheckboxChange($event, row)" class="checkbox">
    </ng-template>
  </ngx-datatable-column>

  <!-- Supplier -->
  <ngx-datatable-column name="Supplier" prop="supplierName" [resizeable]="false" 
    [width]="screenBrakepoint == screenBrakepoints.MinWidth769px ? 200 : screenBrakepoint == screenBrakepoints.MaxWidth768px ? 250 : 200">
    <ng-template let-row="row" ngx-datatable-cell-template [width]="screenBrakepoint == screenBrakepoints.MinWidth769px ? 250 : 200">
      <div class="supplier" *ngIf="row?.supplierName != ''">
        <div class="sup_img_box_processing" *ngIf="row.rowCount == 0 && !row.id">
          <img src="../../../../../assets/img/inboxPage/analyze.gif" alt="">
        </div>
        <!-- <div class="sup_img_box" *ngIf="row.rowCount != 0"
          [ngClass]="{'sup_img_box_1': row.rowCount == 1,'sup_img_box_2': row.rowCount == 2,'sup_img_box_3': row.rowCount ==3,'sup_img_box_4': row.rowCount == 4,'sup_img_box_5': row.rowCount == 5,'sup_img_box_6': row.rowCount == 6}">
          <span>{{row.shortCode}}</span>
        </div> -->
        <div class="sup_img_box {{row.hexColorClass}}"  *ngIf="row.rowCount != 0 && row?.supplierName != null && row.hexColorClass != null">
          <span>{{row?.shortCode}}</span>
        </div>
        <div class="sup_img_box"  *ngIf="row.rowCount != 0 && row?.supplierName != null && row.hexColorClass == null"
        [ngClass]="{'sup_img_box_1': row.rowCount == 1 && row.hexColorClass == null,
                    'sup_img_box_2': row.rowCount == 2 && row.hexColorClass == null,
                    'sup_img_box_3': row.rowCount == 3 && row.hexColorClass == null,
                    'sup_img_box_4': row.rowCount == 4 && row.hexColorClass == null,
                    'sup_img_box_5': row.rowCount == 5 && row.hexColorClass == null,
                    'sup_img_box_6': row.rowCount == 6 && row.hexColorClass == null}">
          <span>{{row?.shortCode}}</span>
        </div>
        <div class="sup_img_box"  *ngIf="row.rowCount != 0 && (row?.supplierName == null || row?.supplierName == '')"
        [ngClass]="{'sup_img_box_1': row.rowCount == 1,'sup_img_box_2': row.rowCount == 2,'sup_img_box_3': row.rowCount ==3,'sup_img_box_4': row.rowCount == 4,'sup_img_box_5': row.rowCount == 5,'sup_img_box_6': row.rowCount == 6}">
          <span>{{row?.shortCode}}</span>
        </div>
        <div class="sup_det">
          <div class="sd_name">
            <span class="name" *ngIf="row.supplierName">{{row.supplierName}}</span>
            <span class="name field-placeholder" *ngIf="!row.supplierName">Add Supplier name</span>
            <div *ngIf="!row.isDemo && (status === statuses.inProcessing && row.contactStatus)" class="clsContStatus">{{row.contactStatus}}</div>
            <div *ngIf="row.isDemo && status === statuses.inProcessing"  class="clsDemo">Demo</div>
            <!--<img *ngIf="row?.submissionMethod == 3" src="../../../../../assets/img/inboxPage/addin.png" alt="Submitted via Inbox Assistant" class="addin-logo" title="Submitted via Inbox Assistant" />-->

          </div>
          <span class="sd_invoice invoice-number" *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px">{{row.invoiceNumber}}</span>
          <div class="sd-mobile" *ngIf="screenBrakepoint != screenBrakepoints.MinWidth769px">
            <span class="sd_invoice invoice-number">{{row.date | formatedDate}}</span>
            <span class="sd_invoice invoice-number" *ngIf="row.accountName">&nbsp;|&nbsp;{{row.accountName}}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <!-- Date -->
  <ngx-datatable-column *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px" prop="date" name="Date" [resizeable]="false" [width]="50">
    <ng-template let-column="date" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value | formatedDate: '-' }}</span>
      <span class="field-placeholder" *ngIf="!value">Add Date</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px" prop="type" name="Type" [resizeable]="false" [width]="80">
    <ng-template let-column="type" let-value="value" ngx-datatable-cell-template>
      <span *ngIf="value">{{ value }}</span>
    </ng-template>
  </ngx-datatable-column>

  <!-- Category -->
  <ngx-datatable-column *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px" name="Category" prop="invoiceLines" [resizeable]="false"  [width]="100" headerClass="left-align-column" cellClass="left-align-column">
    <ng-template let-column="categories" let-value="value" let-row="row" ngx-datatable-cell-template>    
      <div class="field-placeholder" *ngIf="(!row.accountName && value?.length == 0) || (!row.accountName && value?.length > 0 && !value[0].accountName)">Add Category</div>
      <div class="category-row" *ngIf="value.length == 0 && row.accountName">{{row.accountName}}</div>
      <div class="categories-wrapper" *ngIf="value?.length > 0" [ngClass]="shownCategoriesInvoiceId == row.id && value?.length > 0 ? 'expanded-row' : ''">
        <div class="category-row" *ngIf="value.length > 1 && shownCategoriesInvoiceId != row.id">Multiple Categories</div>
        <div class="category-row" *ngIf="value.length == 1 && row.accountName">{{row.accountName}}</div>
        <div *ngIf="shownCategoriesInvoiceId == row.id && value.length > 1" [ngClass]="shownCategoriesInvoiceId == row.id ? 'added-padding' : ''">
          <div class="category-row" *ngFor="let line of value">{{line.accountName}}</div>
        </div>
        <div class="show-details" *ngIf="value?.length > 1" (click)="showMoreCategories(row); $event.stopPropagation()">
          <span *ngIf="shownCategoriesInvoiceId != row.id">Show detail</span>
          <span *ngIf="shownCategoriesInvoiceId == row.id">Hide detail</span>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <!-- Tracking Category 1 -->
  <ngx-datatable-column [width]="100" *ngIf="selectedTrackingCategories?.length > 0 && screenBrakepoint == screenBrakepoints.MinWidth769px">
    <ng-template [width]="100" let-column="column" ngx-datatable-header-template>
      {{selectedTrackingCategories[0].name}}
    </ng-template>
    <ng-template [width]="100" let-row="row" ngx-datatable-cell-template>
      <div *ngIf="row.invoiceLines?.length > 0">
        <div class="categories-wrapper">
          <div class="category-row" *ngIf="row.invoiceLines?.length > 1 && row.invoiceLines[0].trackingOptions?.length > 0 && row.invoiceLines[0].trackingOptions[0] && shownCategoriesInvoiceId != row.id">Multiple</div>
          <div class="category-row" *ngIf="row.invoiceLines?.length == 1 && row.invoiceLines[0].trackingOptions?.length > 0">{{row.invoiceLines[0].trackingOptions[0]}}</div>
          <div *ngIf="shownCategoriesInvoiceId == row.id && row.invoiceLines?.length > 1">
            <div *ngFor="let line of row.invoiceLines" [ngClass]="shownCategoriesInvoiceId == row.id ? 'added-padding' : ''">
              <div class="category-row" *ngIf="line.trackingOptions[0]">{{line.trackingOptions[0]}}</div>
              <div class="category-row" *ngIf="!line.trackingOptions[0]" style="opacity: 0;">Text</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <!-- Tracking Category 2 -->
  <ngx-datatable-column [width]="100" *ngIf="selectedTrackingCategories?.length > 1 && screenBrakepoint == screenBrakepoints.MinWidth769px">
    <ng-template [width]="100" let-column="column" ngx-datatable-header-template>
      {{selectedTrackingCategories[1].name}}
    </ng-template>
    <ng-template [width]="100" let-row="row" ngx-datatable-cell-template>
      <div *ngIf="row.invoiceLines?.length > 0">
        <div class="categories-wrapper">
          <div class="category-row" *ngIf="row.invoiceLines?.length > 1 && row.invoiceLines[0].trackingOptions?.length > 0 && row.invoiceLines[0].trackingOptions[1] && shownCategoriesInvoiceId != row.id">Multiple</div>
          <div class="category-row" *ngIf="row.invoiceLines?.length == 1 && row.invoiceLines[0].trackingOptions?.length > 1 && row.invoiceLines[0].trackingOptionNames?.length > 0">{{row.invoiceLines[0].trackingOptionNames[1]}}</div>
          <div *ngIf="shownCategoriesInvoiceId == row.id && row.invoiceLines?.length > 1">
            <div *ngFor="let line of row.invoiceLines" [ngClass]="shownCategoriesInvoiceId == row.id ? 'added-padding' : ''">
              <div class="category-row" *ngIf="line.trackingOptions[1]">{{line.trackingOptionNames[1]}}</div>
              <div class="category-row" *ngIf="!line.trackingOptions[1]" style="opacity: 0;">Text</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <!-- Total -->
  <ngx-datatable-column [width]="100" prop="totalOutstanding" name="Total" *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px">
    <ng-template [width]="100" let-column="column" ngx-datatable-header-template>
      <div class="amountCellHeader">
        Total
      </div>
    </ng-template>
    <ng-template [width]="100" let-row="row" ngx-datatable-cell-template>
      <div class="" *ngIf="shownCategoriesInvoiceId != row.id">
        <span *ngIf="row.amountDue === 'Infinity'"> {{ row.total | number : '1.2-2'}}</span>
        <span *ngIf="row.amountDue > 0"> {{ row.amountDue | number : '1.2-2'}}</span>
        <span *ngIf="row.amountDue == 0 || row.amountDue == null"> {{ 0.00 | number : '1.2-2'}}</span>
        <span *ngIf="row.amountDue < 0"> {{row.amountDue | positiveNumber : '1.2-2'}}</span>
      </div>
      <div class="categories-wrapper" [ngClass]="shownCategoriesInvoiceId == row.id && row.invoiceLines?.length > 0 ? 'added-padding' : ''" *ngIf="row.invoiceLines && row.invoiceLines.length > 0 && shownCategoriesInvoiceId == row.id">
        <div *ngIf="shownCategoriesInvoiceId == row.id">
          <div class="category-row" *ngFor="let line of row.invoiceLines">{{line.amountDue | number : '1.2-2'}}</div>
        </div>
      </div>
      <span class="show-amount-on-hover" *ngIf="shownCategoriesInvoiceId != row.id">
        <sup class="amount-text" *ngIf="row.unConvertedAmount > 0 && row.invoiceCurrency != 0">{{row.invoiceCurrency}}</sup>
        <sup class="hide-untill-hover">{{row.unConvertedAmount | number : '1.2-2'}} {{row.invoiceCurrency}}</sup>
      </span>
    </ng-template>
  </ngx-datatable-column>

    <!-- Total Mobile -->
    <ngx-datatable-column [width]="50" prop="totalOutstanding" name="Total" *ngIf="screenBrakepoint != screenBrakepoints.MinWidth769px">
      <ng-template [width]="50" let-row="row" ngx-datatable-cell-template>
        <div class="amountcell mobile" *ngIf="shownCategoriesInvoiceId != row.id">
          <span *ngIf="row.amountDue === 'Infinity'"> {{ row.total | number : '1.2-2'}}</span>
          <span *ngIf="row.amountDue > 0"> {{ row.amountDue | number : '1.2-2'}}</span>
          <span *ngIf="row.amountDue == 0 || row.amountDue == null"> {{ 0.00 | number : '1.2-2'}}</span>
          <span class="show-amount-on-hover" *ngIf="shownCategoriesInvoiceId != row.id">
            <span class="amount-text" *ngIf="row.unConvertedAmount > 0 && row.invoiceCurrency != 0">{{row.invoiceCurrency}}</span>
            <span class="hide-untill-hover">{{row.unConvertedAmount | number : '1.2-2'}} {{row.invoiceCurrency}}</span>
          </span>
        </div>
      </ng-template>
    </ngx-datatable-column>

  <!-- Tags -->
  <ngx-datatable-column *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px" name="Tags" [sortable]="false" [resizeable]="false"  [width]="150">
    <ng-template let-column="tags" let-value="value" let-row="row" ngx-datatable-cell-template>
      <img *ngIf="status === statuses.inProcessing && row?.suggestedMatchCount > 0" src="../../../../../assets/img/inboxPage/suggested-match.svg" matTooltip="Potential Match - Missing Paperwork" [matTooltipShowDelay]="showToolTipDelay" class="suggestedmatch-logo" title="Potential Match - Missing Paperwork" />
      <div class="tag-container" *ngIf="value?.length > 0">
        <span *ngFor="let tag of value"
              class="tag"
              [ngStyle]="{'color': tag.textColor, 'background': tag.backColor}"
              (click)="$event.stopPropagation()">
          <span>{{tag.tagName}}</span>
          <span class="del-icon" (click)="deleteTag(tag, row)" matTooltip="Delete from the invoice" [matTooltipShowDelay]="showToolTipDelay">
            <i class="fas fa-close" [ngStyle]="{'color': tag.textColor}"></i>
          </span>
        </span>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="maxCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="page"
        [size]="pageSize" [count]="maxCount" [hidden]="status != 'Processed'"
        (change)="table.onFooterPage($event); clickOn($event)">
      </datatable-pager>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>


<div [ngClass]="showSidebar ? 'sidenav sidenav-show sidebar40' : 'sidenav'">
  <a [routerLink]="" class="closebtn" (click)="toggleFilter()" style="margin-bottom: 10px;">&times;</a>
  <div class="sidenav_in">
      <div class="form-group">
        <app-upload-document [isHorizontalDirection]=isHorizontalDirection></app-upload-document>
      </div>
  </div>
</div>
