import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { UserService } from 'src/app/data/service/user.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { EnumItem } from '../../models/enum-item';
import { enumSelector } from 'src/app/core/util/enum-selector';
import { fromCamelCase } from 'src/app/core/util/camel-case-converter';

@Component({
  selector: 'app-bank-transaction-detail',
  templateUrl: './bank-transaction-detail.component.html',
  styleUrls: ['./bank-transaction-detail.component.scss']
})
export class BankTransactionDetailComponent implements OnInit {

  @Input() activities: any=[{transactionDate:new Date()}];
  @Input() curIdx :number;
  @Output() assingTrans = new EventEmitter<object>();

  organizationId: string = null;
  usersList: any[] = [];
  statuslist=["Unassigned","Assigned","Actioned","Completed"];
  account:any=[{name:"Unassigned"},{name:"Assigned"}]

  isEditable = false;

  noteMentions: any[] = [];
  @ViewChild('noteinput') noteinput: ElementRef;
  isStatusChg:boolean=false;
  assignTo :string =''
  idx=0;
  ismention=false;
  constructor( private _userService: UserService,
    private readonly _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService) { }

  ngOnInit(): void {
    this.organizationId = this._organizationService.selectedOrganization;
    this.getUsers();
    if(this.activities[this.curIdx]['assignId'] || this.activities[this.curIdx]['assignName'])
    {
       this.assignTo = this.activities[this.curIdx]['assignName'];
    }


    // if(this.activities[this.curIdx]['assignId'])
    // {

    //   this.assignTo = '@' + this.activities[this.curIdx]['assignName'];
    //   let s = document.getElementById('noteinput');
    //   setTimeout(() => {
    //     this.assignTo = '@' + this.activities[this.curIdx]['assignName'];
    //     s.setAttribute("value", '@' + this.activities[this.curIdx]['assignName']);

    //   },10);
    //   this.noteMentions.push({id:this.activities[this.curIdx]['assignId'],emailId:this.activities[this.curIdx]['assignEmail'],name:this.activities[this.curIdx]['assignName']})
    // }
  }



  @HostListener('keydown.arrowup', ['$event']) keyUp(event){
    if(this.ismention && this.idx > 0){
      this.idx--;
    }
  }
  @HostListener('keydown.arrowdown', ['$event']) keyDown(event){
    if(this.ismention && this.idx < 2){
      this.idx++;
    }
  }
  @HostListener('keydown.enter', ['$event']) keyEnter(event){
    if(this.ismention)
    {
      let id= this.usersList[this.idx]['id']

      // this.assignTo =this.usersList[this.idx]['name'];
      // let data ={Action:'assigntrans',AssignedTo :id}
      // this.saveTransactionStatus(data);

      for(let i=0;i<this.activities.length;i++){

        this.assignTo =this.usersList[this.idx]['name'];
        this.activities[i].assignName=this.usersList[this.idx]['name'];
        let data ={Action:'assigntrans',AssignedTo :this.activities[i].unreconciledReportId}
        this.saveTransactionStatus(data);
      }
      this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success',"");

      this.ismention=false;
    }
  }
  // @HostListener('mouseup', ['$event']) mouseUp(event){
  //   console.log(event.classList.contains('assignUser'),"assignUser");
  //   // if(!event.srcElement.test('assignUser')){
  //   // this.ismention=false;
  //   // }
  // }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if(!(<HTMLElement> event.target).matches('.assignUser')){
      this.ismention=false;
    }
  }

  getinit(value)
  {
    var matches = value.match(/\b(\w)/g);
    return matches.join('');
  }
  orgusersList:any =[]
  getUsers() {
    this._userService.getUsersByOrganizationId(this.organizationId).subscribe((val) => {
      val.data.forEach((value, key) => {
        var user = {
          'id': value["id"],
          'name': value["name"],
          'emailId': value["email"],
        }
        if(value["name"]){
        this.usersList.push(user);
        this.orgusersList.push(user);
        }
      });
    });
  }


  onChange(event: any) {
   // this.orgusersList = this.usersList.findIndex((item) => item.name.startsWith(this.assignTo)) > -1;
    if(this.assignTo) {
    this.usersList = this.orgusersList.filter(option => option.name.toLowerCase().includes(this.assignTo)) ;// this.orgusersList.filter(option => option.name.startsWith(this.assignTo) > -1)
    }
    else{
      this.usersList = this.orgusersList
    }
   }


  async selectItem(obj:any){

    for(let i=0;i<this.activities.length;i++){

      this.assignTo =obj.name;
      this.activities[i].assignName=obj.name;
      let data ={Action:'assigntrans',AssignedTo :this.activities[i].unreconciledReportId}
      await this.saveTransactionStatus(data);
    }
    this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success',"");
  }

  // onSelectType(eventValue: string) {

  //   let data ={Action:'changestatus',Status:eventValue}
  //   this.saveTransactionStatus(data);
  // }


  async saveTransactionStatus(data) {
    data.AssignedBy= sessionStorage.Id,
    data.UnreconciledReportIds = this.activities[this.curIdx]['unreconciledReportId'],

    this._organizationService.saveTransactionStatus(data)
      .subscribe(
         (result: any) => {
          console.log('kjjjj')
          if(result.status==1){
            if(data.Action=='assigntrans')
            {
              this.assingTrans.emit({id:this.activities[this.curIdx]['unreconciledReportId'],assignName:this.assignTo,status: !this.activities[this.curIdx]["status"]  || this.activities[this.curIdx]["status"] =="Unassigned" ? "Assigned" :this.activities[this.curIdx]["status"]});
            }
            else if(data.Action=='changestatus'){


              this.assingTrans.emit({id:this.activities[this.curIdx]['unreconciledReportId'],assignName:this.assignTo,status:data.Status});
            }

           // this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', result.Message);
          }
          else{
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', result.Message);
          }
         }
       );
  }

}
