import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpFinalModel, SignUpFullModel, SignUpUserModel } from '../../../../core/model/signup-model';
import { LoginModel } from '../../../../core/model/login-model';
import { AccountingIntegration } from '../../../../data/enums/accounting-integration';
import { RegisterService } from '../../../../data/service/register.service';
import { finalize, take } from 'rxjs/operators';
import { AuthService } from '../../../../core/service/auth/auth.service';
import { UserService } from '../../../../data/service/user.service';
import { NotificationsHubService } from '../../../../core/service/hubs/notifications-hub.service';
@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {
  SignUpForm: FormGroup;
  isWarningShown: boolean = false;
  SignUpUserModel: SignUpUserModel | null;
  signUpModel: LoginModel | null;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private authService: AuthService,
    private _userService: UserService,
    private readonly _notificationsHubService: NotificationsHubService) { }

  get signUpFormControl() {
    return this.SignUpForm.controls;
  }

  ngOnInit(): void {
    // Create the form
    this.SignUpForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      companyname: ['', [Validators.required]],
    });
  }
  loading: boolean = false;
  nextStep() {
    this.loading = true;
    this.SignUpForm.markAllAsTouched();
    this.SignUpUserModel = {
      firstname: this.signUpFormControl.firstname.value,
      lastname: this.signUpFormControl.lastname.value,
      companyname: this.signUpFormControl.companyname.value,
      name: this.signUpFormControl.firstname.value + " " + this.signUpFormControl.lastname.value
    }
    if (this.SignUpForm.invalid) {
      this.isWarningShown = true;
      return;

    } else {
      let item = JSON.parse(sessionStorage.getItem("newuser"));
      item.firstname = this.SignUpUserModel.firstname;
      item.lastname = this.SignUpUserModel.lastname;
      item.companyname = this.SignUpUserModel.companyname;
      item.name = this.SignUpUserModel.name;
      sessionStorage.setItem("newuser", JSON.stringify(item));
      //this.router.navigate(['/signup/step-three' ]);
      this.createProfile()
    }
  }

  SignupFullModel: SignUpFullModel | null;
  signupModel: SignUpFinalModel;
  loginModel: LoginModel | null; 
  newOrganizationID: string;
  createProfile() {
    this.SignupFullModel = JSON.parse(sessionStorage.getItem("newuser"));     

    this.signupModel = {
      domain: "",
      user: this.SignupFullModel,
      organisationIds: "",
      invitedUsers: null,
      organisationId: "",//this.organizationIds[0], 
    }

    if (this.signupModel != null && this.signupModel != undefined) {
      //this.loading = true;
      this.registerService.signUpNew('Account/CreateProfile', this.signupModel)
        .subscribe((response) => {
          if (response.status === 0) {
            // this.loading = false;
            // this.toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'New user registered. Check email for confirmation.');
           
            this.newOrganizationID = response.otherData.organizationID;
            this.loginModel = {
              email: this.SignupFullModel.email.toLowerCase(),
              password: this.SignupFullModel.password,
              accountRemember: false
            }
            this.authService.signIn(this.loginModel)
              .pipe(
                take(1),
                finalize  (() => this.loading = false))
              .subscribe((response) => {
                if (response.status != 0) {
                  //    this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
                }
                else {
                  this.setUser(response);
                  this._notificationsHubService.start();
                  this.loading = false;
                  this.router.navigate(['/signup/wizzard']);
                }
              }, (error) => {
                //  this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', error.message);
              });
          }
          else {
            //this.loading = false;
            //this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
          }
        }, (error) => {
          //this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', 'Error inviting users');
        });
    }
  }
  setUser(response) {
    sessionStorage.UserName = response.data.user.name;
    sessionStorage.Id = response.data.user.id;
    sessionStorage.UserId = response.data.user.userId;
    localStorage.setItem('UserName', response.data.user.name);
    localStorage.setItem('Email', response.data.user.email);
    localStorage.setItem('UserId', response.data.user.userId);
    localStorage.setItem('Id', response.data.user.id);
    localStorage.setItem('SelectedOrganization', this.newOrganizationID);
    //localStorage.setItem('SelectedOrganization', response.data.user.organizationId);
    localStorage.setItem('CreatedAt', response.data.user.created_date);
    this._userService.upUserDetails = JSON.stringify(response.data.user);
    this._userService.setUserRoleData(response.data.user.role);
     
      sessionStorage.organizationId = this.newOrganizationID;
      sessionStorage.selectedOrganization =   this.newOrganizationID;
      //this.AddandUpdatesupplierXeroData(response.data.user.organizationId, response.data.user.email)
    
  }
}
